@tailwind base;
@tailwind components;
@tailwind utilities;

.circle-text {
  position: relative;
  z-index: 10;
  overflow: visible;
  margin-right: 5px;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
}

.tg-buy{
  p{
    text-shadow: 
    1px 1px 0 #000,   /* Bottom-right shadow */
    -1px -1px 0 #000, /* Top-left shadow */
    -1px 1px 0 #000,  /* Bottom-left shadow */
    1px -1px 0 #000;  /* Top-right shadow */
    font-family: "Roboto", system-ui;
  }
}


body {
  -webkit-text-size-adjust: 100%; /* For Safari and older Android browsers */
  text-size-adjust: 100%; /* For Chrome 54+, Edge 79+, and modern browsers */
  overflow-x:hidden;
}

body {

    font-family: "Freckle Face", system-ui;
    font-weight: 400;
    font-style: normal;
  }

  p {
    /* font-size: 24px; */
    color: lightgreen;
    color: white !important;
    font-size: clamp(14px, 1vw, 24px);
  }

  h1,h2,h3,h4,h5,h6 {
    color: #F0F0F0;
  }


  .text-outline, h1,h2,h3,h4,h5,h6, p, span {
    text-shadow: 
      1px 1px 0 #000,   /* Bottom-right shadow */
      -1px -1px 0 #000, /* Top-left shadow */
      -1px 1px 0 #000,  /* Bottom-left shadow */
      1px -1px 0 #000;  /* Top-right shadow */
  }
  
.text-gold {
  color: gold !important;
}

/* styles.css */
.btn-gold {
  background-color: white; /* Gold color */
  color: black; /* Text color */
  border: none; /* Remove default border */
}

.btn-gold:hover {
  background-color: lightblue; /* Darker gold for hover effect */
  color: white; /* Ensure text color remains white on hover */
}

.link-style {
  text-decoration: none; /* Remove underline */
  color: white; /* Ensure the text color is white */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
}

.link-style:hover {
  color: lightblue; /* Optional: change color on hover */
}

.icon {
  font-size: 2rem; /* Adjust the size of the icons as needed */
  margin-right: 8px; /* Space between icon and text */
}

.mentality-img {
  width: 700px;
  height: 700px;
  /* border-color: gold !important; Set border color to gold */
  /* border-style: solid; Apply border style */
  /* border-width: 2px; Adjust border width as needed */
}

.mentality-logo {
  width: 80px; /* Ensure the width is 50px */
  height: 80px; /* Ensure the height is 50px */
  background-color: white; /* White background */
  padding: 5px; /* Optional: Adds some padding around the logo */
  border-radius: 50%; /* Makes the logo circular */
  /* border: 2px solid gold; Gold border */
}

.border-mentality {
  /* border-color: gold !important; Set border color to gold */
  /* border-style: solid; /* Apply border style */
  /* border-width: 2px; Adjust border width as needed */
}

.row {
  margin: 0;
  padding: 0;
}

.sticky-top {
  position: sticky;
  top: 0; /* Sticks the header to the top */
  z-index: 1000; /* Ensures the header is above other content */
}

.fixed-top {
  position: fixed;
  top: 0; /* Sticks the header to the top */
  width: 100%; /* Ensures the header spans the full width */
  z-index: 1000; /* Ensures the header is above other content */
}

.russo-one-regular {
  font-family: "Russo One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@keyframes svelte-9u00oj-shimmer {
  0% {
      background-position: -200%
  }

  to {
      background-position: 200%
  }
}

@keyframes svelte-9u00oj-rotate {
  to {
      transform: rotateY(360deg)
  }
}

.coin-wrapper.svelte-9u00oj.svelte-9u00oj {
  position: relative;
  transform: translateZ(200px)
}

.coin.svelte-9u00oj.svelte-9u00oj {
  font-size: 250px;
  width: .125em;
  height: 1.3em;
  background: linear-gradient(var(--lighter-color-property),var(--darker-color-property));
  margin: auto;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: svelte-9u00oj-rotate 4s infinite linear;
  transform-style: preserve-3d
}

.side.svelte-9u00oj.svelte-9u00oj {
  position: relative;
  overflow: hidden;
  background-position: center
}

.side.svelte-9u00oj.svelte-9u00oj:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right,#fff0 30%,#ffffff4d,#fff0 70%);
  animation: svelte-9u00oj-shimmer 2s infinite;
  background-size: 200% 200%;
  background-repeat: no-repeat;
  z-index: 2
}

.coin.svelte-9u00oj .side.svelte-9u00oj,.coin.svelte-9u00oj.svelte-9u00oj:before,.coin.svelte-9u00oj.svelte-9u00oj:after {
  content: "";
  position: absolute;
  height: 1.3em;
  width: 1.3em;
  overflow: hidden;
  border-radius: 50%;
  right: -.524em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  backface-visibility: hidden
}

.coin.svelte-9u00oj .tails.svelte-9u00oj,.coin.svelte-9u00oj.svelte-9u00oj:after {
  left: -.524em;
  transform: rotateY(90deg)
}

.coin.svelte-9u00oj.svelte-9u00oj:before,.coin.svelte-9u00oj.svelte-9u00oj:after {
  background: linear-gradient(var(--lighter-color-property),var(--darker-color-property));
  backface-visibility: hidden;
  transform: rotateY(90deg)
}

.coin.svelte-9u00oj.svelte-9u00oj:after {
  transform: rotateY(-90deg)
}

.imageCoin.svelte-9u00oj.svelte-9u00oj {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  min-height: 1em;
  background-size: cover;
  background-image: var(--img-url-property);
  background-position: center;
  background-color: var(--average-color-property);
  box-shadow: inset 0 0 0 5px var(--average-color-property);
  border-radius: 50%
}

.side.tails.svelte-9u00oj.svelte-9u00oj {
  background-image: var(--img-url-property);
  background-position: center;
  background-color: var(--average-color-property);
  box-shadow: inset 0 0 0 5px var(--average-color-property);
  background-size: cover;
  border-radius: 50%
}

.max.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  background: #48de80!important;
  -webkit-background-clip: text!important;
  -moz-background-clip: text!important;
  background-clip: text!important;
  background-size: 100% 200%!important;
  color: transparent!important
}

.loading.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  color: #b8faaf;
  font-weight: 700
}

#header.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px;
  z-index: 5
}

#left-header.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  align-items: center
}

#headerImage.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  max-height: 72px;
  margin-right: 20px;
  margin-left: 10px;
  border-radius: 15px
}

.header-icon.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  padding: 0;
  border-radius: 10px;
  color: #fff;
  font-size: 28px
}

#name.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  color: #fff;
  margin: 0;
  line-height: 1.2em
}

#right-header.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  align-items: center
}

#right-header.svelte-vrm4ox a.svelte-vrm4ox.svelte-vrm4ox {
  margin-left: 10px;
  margin-right: 10px
}

#right-header.svelte-vrm4ox button.svelte-vrm4ox.svelte-vrm4ox {
  background-color: none;
  color: #fff;
  border: 2px solid var(--dominant-color);
  font-weight: 700;
  font-size: 16px;
  padding: 9px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform .1s ease,box-shadow .1s ease
}

#right-header.svelte-vrm4ox button.svelte-vrm4ox img.svelte-vrm4ox {
  display: inline;
  height: 20px;
  margin-right: 10px
}

#right-header.svelte-vrm4ox button.svelte-vrm4ox.svelte-vrm4ox:hover {
  box-shadow: 0 0 var(--dominant-color)
}

.header-tg.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  height: 30px;
  margin-right: 15px
}

.header-tg.svelte-vrm4ox img.svelte-vrm4ox.svelte-vrm4ox {
  height: 30px
}

#hero.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  max-width: 1000px;
  padding: 50px;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  margin: 30px auto
}

#buyButtonLink.svelte-vrm4ox button.svelte-vrm4ox.svelte-vrm4ox {
  background-color: var(--comp-color);
  color: #fff;
  border: 2px solid var(--comp-color);
  font-weight: 700;
  font-size: 20px;
  padding: 9px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform .1s ease,box-shadow .1s ease
}

#heroDetails.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  width: 60%;
  line-height: 1.5em;
  padding: 0 20px 20px
}

#description.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  margin-top: 15px
}

#heroImage.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: relative;
  width: 300px
}

#heroImageBg.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: absolute;
  width: 300px;
  height: 300px;
  padding: 50px;
  background-color: var(--super-dark-color);
  border-radius: 50%;
  filter: blur(50px);
  -webkit-filter: blur(50px);
  opacity: .5
}

#spinningCoin.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  padding-right: 15px
}

.dexpaid-wrapper.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  margin: auto
}

.dexpaid.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  align-items: center;
  background: var(--darker-color);
  padding: 30px;
  border-radius: 25px;
  margin: 40px 15px;
  font-weight: 600;
  font-size: 18px
}

.dexpaid.svelte-vrm4ox p.svelte-vrm4ox.svelte-vrm4ox {
  font-size: 24px
}

.dexpaid.svelte-vrm4ox img.svelte-vrm4ox.svelte-vrm4ox {
  display: inline
}

.paid.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 180px;
  background-image: var(--token-image);
  background-size: cover;
  border-radius: 16px;
  letter-spacing: 2px
}

.dexpaid.svelte-vrm4ox button.svelte-vrm4ox.svelte-vrm4ox {
  background: var(--comp-color);
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  margin: 10px
}

.dexpaid.svelte-vrm4ox a.svelte-vrm4ox.svelte-vrm4ox {
  color: var(--lighter-color)
}

.dexpaid-icon.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  padding: 0;
  margin-top: -3px;
  color: #fff;
  font-size: 16px
}

#keyInfo.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 50px auto 20px;
  gap: 20px
}

.infoItem.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  border: 3px solid var(--dominant-color);
  background: lightgreen;
  border-radius: 25px;
  color: var(--comp-color);
  padding: 20px;
  text-align: center;
  line-height: 1.5em;
  width: 280px
}

.infoItem.svelte-vrm4ox h2.svelte-vrm4ox.svelte-vrm4ox {
  margin-bottom: 10px;
  font-size: 1.4em;
  font-weight: 800;
  color: var(--dominant-color)
}

.infoItem.svelte-vrm4ox p.svelte-vrm4ox.svelte-vrm4ox {
  color: var(--darker-color)
}

.wave-container.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  width: 100vw;
  overflow: hidden;
  position: relative;
  line-height: 0;
  padding: 0;
  margin-bottom: -1px!important;
  margin: auto
}

.wave-container.svelte-vrm4ox svg.svelte-vrm4ox.svelte-vrm4ox {
  display: block;
  width: 110vw;
  height: auto;
  padding: 0;
  overflow: hidden
}

.wave-container.svelte-vrm4ox path.svelte-vrm4ox.svelte-vrm4ox {
  fill: var(--dominant-color)
}

.wave-container.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox:before {
  content: "";
  position: absolute;
  bottom: -.3%;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .4;
  background-image: var(--noise-bg-image);
  background-size: 100px;
  background-repeat: repeat
}

.wave-container.wave-bottom.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  transform: rotate(180deg);
  margin-top: -1px!important
}

.section-title.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  text-align: center;
  margin: 50px 0 40px
}

#tokenomics.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: relative;
  background: var(--dominant-color);
  width: 100%;
  padding: 80px 0
}

#tokenomics.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background-image: var(--noise-bg-image);
  background-size: 100px;
  background-repeat: repeat;
  z-index: 0
}

#tokenomics-content.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: relative;
  margin: auto;
  max-width: 850px;
  z-index: 1
}

#address.svelte-vrm4ox p.svelte-vrm4ox.svelte-vrm4ox {
  cursor: pointer;
  display: inline-block;
  border-radius: 10px;
  border: 3px solid var(--dominant-color);
  padding: 10px 15px;
  margin-bottom: 15px;
  margin-top: 15px
}

#address.svelte-vrm4ox p.svelte-vrm4ox.svelte-vrm4ox:hover {
  border: 3px solid #fff
}

.copy-icon.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: inline-block;
  margin-right: 8px
}

.info-card.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 28px;
  margin: auto
}

.info-section.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 30px
}

.info-section.svelte-vrm4ox p.svelte-vrm4ox.svelte-vrm4ox {
  margin: 5px 0
}

.info-section.svelte-vrm4ox p span.svelte-vrm4ox.svelte-vrm4ox {
  font-weight: 700
}

.highlight.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  font-size: 2.5em;
  font-weight: 700
}

.market-cap.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: relative;
  background-color: var(--darker-color);
  padding: 30px;
  border-radius: 25px;
  text-align: center;
  width: 280px;
  overflow: hidden;
  background-image: var(--noise-bg-image);
  background-size: 100px;
  background-repeat: repeat;
  z-index: 5
}

.market-cap.svelte-vrm4ox p.svelte-vrm4ox.svelte-vrm4ox {
  margin: 0
}

.market-cap.svelte-vrm4ox .value.svelte-vrm4ox.svelte-vrm4ox {
  font-size: 2em;
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px
}

.mc-wrapper.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  background: #4ade80;
  background: linear-gradient(40deg,#833ab4,#fd1d1d,#fcb045,#a2fc45,#4586fc);
  padding: 2px;
  border-radius: 40px 290px;
  transition: border-radius 1s ease-in-out;
  animation: svelte-vrm4ox-border-radius-animation 2s infinite alternate ease-in-out
}

@keyframes svelte-vrm4ox-border-radius-animation {
  0% {
      border-radius: 40px 190px
  }

  to {
      border-radius: 190px 40px
  }
}

.mc-wrapper.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox:hover {
  background: #4ade80
}

.pumpfun.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  background: #4ade80;
  border-radius: 10px;
  padding: 12px 20px 7px;
  font-weight: 800;
  margin-top: 10px;
  color: #000;
  font-size: .75em;
  letter-spacing: .5px
}

.live-status.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center
}

.live-dot.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  width: 10px;
  height: 10px;
  background-color: #4ade80;
  border-radius: 50%;
  margin-left: 8px;
  animation: svelte-vrm4ox-pulse 1.5s infinite ease-in-out
}

@keyframes svelte-vrm4ox-pulse {
  0% {
      opacity: 1
  }

  50% {
      opacity: 1
  }

  to {
      opacity: 0
  }
}

.live-status.svelte-vrm4ox p.svelte-vrm4ox.svelte-vrm4ox {
  margin: 0;
  font-weight: 600
}

#how-to.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: relative;
  width: 100%;
  padding: 80px 0
}

.tg-buy.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  border-radius: 25px;
  margin-top: 2em;
  margin-bottom: 2em
}

.steps.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;
  background: darkseagrean;
}

.step-container.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  width: 33%
}

.step.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  background: #fff;
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 0;
  /* background: linear-gradient(to bottom, #1e92e7, #87CEEB);; */
  background: rgba(30, 156, 240, 0.733);
}

.step.svelte-vrm4ox p.svelte-vrm4ox.svelte-vrm4ox {
  color: #000;
  padding: 20px;
  margin: 0;
}

.stepHeader.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  background: var(--comp-color);
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  width: 100%;
  margin: 0;
  background-image: var(--noise-bg-image);
  background-size: 100px;
  background-repeat: repeat;
  background: #0a0a2aad;
}

.stepHeader.svelte-vrm4ox h3.svelte-vrm4ox.svelte-vrm4ox {
  color: #fff;
  padding: 10px;
  margin: 0
}

#botLinks.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  padding: 0 30px 20px;
  margin-top: -10px
}

#botLinks.svelte-vrm4ox img.svelte-vrm4ox.svelte-vrm4ox {
  height: 55px;
  border-radius: 14px;
  border: 1px solid rgba(0,0,0,.3)
}

#botLinks.svelte-vrm4ox img.svelte-vrm4ox.svelte-vrm4ox:hover {
  transform: translateY(2px)
}

h1.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  color: #fff;
  font-size: 2.5em;
  font-weight: 800;
  -webkit-text-stroke: 8px var(--super-dark-color);
  paint-order: stroke fill;
  text-shadow: 0px 9px var(--super-dark-color),-3px 8px var(--super-dark-color),3px 8px var(--super-dark-color)
}

.large-text.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  font-size: 3.5em
}

h2.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  color: #fff;
  font-size: 1.5em;
  font-weight: 700
}

h3.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  color: #fff;
  margin: 0 0 10px;
  font-weight: 700
}

button.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  font-size: 1em;
  cursor: pointer
}

button.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox:hover {
  transform: translateY(2px)
}

#bold-text.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  font-weight: 700
}

a.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  text-decoration: none
}

a.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox:hover {
  cursor: pointer!important
}

p.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  color: #fff;
  font-weight: 500
}

.wrapper.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: relative;
  overflow: scroll;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  opacity: 0;
  transition: opacity .2s ease-in-out
}

.fade-in.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  opacity: 1!important;
  transition: opacity .2s ease-in-out
}

.background-blur.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: absolute;
  background-image: var(--token-image);
  filter: blur(90px);
  -webkit-filter: blur(90px);
  background-position: cover;
  background-size: 150%;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  z-index: 1;
  overflow: hidden
}

.background-blur-small.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: absolute;
  background-image: var(--token-image);
  filter: blur(30px);
  -webkit-filter: blur(30px);
  background-position: center;
  background-size: 200%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: .25
}

.bg-blur.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: absolute;
  background-image: var(--token-image);
  filter: blur(200px);
  -webkit-filter: blur(200px);
  background-position: cover;
  background-size: 150%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: .3
}

#content.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: relative;
  padding: 3em 0 0;
  width: 100%;
  margin: auto;
  z-index: 2;
  background: var(--super-dark-color);
  background-image: var(--noise-bg-image);
  background-size: 100px;
  background-repeat: repeat
}

.footer.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  background-color: #0006;
  color: #fff;
  padding: 0
}

.footer-wrapper.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px 0
}

.footer-logo.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  align-items: center
}

.footer-logo.svelte-vrm4ox span.svelte-vrm4ox.svelte-vrm4ox {
  font-weight: 800
}

.footer-logo.svelte-vrm4ox img.svelte-vrm4ox.svelte-vrm4ox {
  margin-right: 10px;
  height: 66px;
  border-radius: 10px
}

.footer-section.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex
}

.footer-column.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  margin-left: 90px
}

.footer-column.svelte-vrm4ox h4.svelte-vrm4ox.svelte-vrm4ox {
  color: var(--lighter-color);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 10px
}

.footer-link.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  color: #fff;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600
}

.footer-bottom.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  text-align: center;
  width: 100%;
  padding: 50px 0 20px;
  font-size: 16px;
  font-weight: 600
}

.marquee-wrapper.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: relative;
  width: 100%;
  height: 255px;
  padding-top: 130px;
  overflow-x: hidden;
  z-index: 3;
  margin-bottom: -125px
}

.slanted-marquee-wrapper.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: relative;
  width: 110%;
  height: 80px;
  margin-left: -10px
}

.slanted-bar.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dominant-color)
}

.slanted-bar.behind.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  z-index: 1;
  background-color: var(--darker-color);
  transform: rotate(-3deg);
  transform-origin: center;
  height: 120%;
  margin-top: -60px
}

.marquee-container.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  position: relative;
  top: -12px;
  left: 0;
  width: 100%;
  height: 130%;
  overflow: hidden;
  z-index: 3;
  transform: translateY(-50%) rotate(-6deg);
  transform-origin: center
}

.marquee.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  display: flex;
  width: -moz-max-content;
  width: max-content;
  animation: svelte-vrm4ox-marquee 20s linear infinite;
  border: 3px solid var(--darker-color);
  height: 100%
}

.marquee.svelte-vrm4ox span.svelte-vrm4ox.svelte-vrm4ox {
  display: inline-block;
  font-size: 3.5em;
  padding: 0 .35em;
  margin-left: -.1em;
  color: white;
  font-weight: 800;
  line-height: 97px;
  text-transform: uppercase;
  transform: skew(-7deg);
  background-color: var(--dominant-color)
}

#marqueeImage.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
  height: .85em;
  margin-left: 20px;
  margin-top: -20px;
  border-radius: 50%;
  display: inline;
  transform: skew(7deg) rotate(7deg)
}

@keyframes svelte-vrm4ox-marquee {
  0% {
      transform: translate(0)
  }

  to {
      transform: translate(-50%)
  }
}

@media (max-width: 768px) {
  body {
      margin:0;
      padding: 0
  }

  h2.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      color: #fff;
      font-size: 1.2em
  }

  .wrapper.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      padding: 0
  }

  #header.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      padding: 20px 10px
  }

  #headerImage.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      max-height: 55px
  }

  #right-header.svelte-vrm4ox button.svelte-vrm4ox.svelte-vrm4ox {
      padding: 6px 8px;
      font-size: 14px
  }

  #right-header.svelte-vrm4ox button.svelte-vrm4ox img.svelte-vrm4ox {
      margin: 0;
      padding: 0;
      height: 0
  }

  .header-tg.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      margin-right: 5px
  }

  .header-tg.svelte-vrm4ox img.svelte-vrm4ox.svelte-vrm4ox {
      height: 30px
  }

  .background-blur.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox,.bg-blur.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      display: none
  }

  #hero.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      flex-direction: column-reverse;
      padding: 0;
      text-align: center;
      margin-bottom: 40px
  }

  #heroDetails.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      width: 100%;
      margin-top: 40px
  }

  #heroImage.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      width: 80%
  }

  #heroImageBg.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      position: absolute;
      width: 250px;
      height: 250px;
      padding: 50px;
      margin-left: 40px
  }

  #spinningCoin.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      padding-right: 0
  }

  .dexpaid.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      flex-direction: column
  }

  #keyInfo.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 10px;
      margin-bottom: 60px
  }

  .infoItem.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      width: 100%
  }

  .section-title.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      text-align: center;
      margin: 20px 0
  }

  #tokenomics.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      padding: 60px 0
  }

  .info-card.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      display: flex;
      flex-direction: column;
      margin: 10px;
      gap: 40px
  }

  .total-supply.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      margin-top: 40px
  }

  .market-cap.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      min-width: 100%
  }

  #how-to.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      padding: 60px 0
  }

  .tg-buy.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      padding: 20px;
      margin: 10px
  }

  .steps.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      flex-direction: column
  }

  .step-container.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      width: 100%
  }

  .footer-wrapper.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      flex-direction: column
  }

  .footer-section.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      flex-wrap: wrap;
      justify-content: center
  }

  .footer-column.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      margin: 30px 10px 10px!important;
      width: 48%
  }
}

@media (max-width: 960px) {
  .footer-column.svelte-vrm4ox.svelte-vrm4ox.svelte-vrm4ox {
      margin-left:45px
  }
}
