.image-container {
    position: relative; /* This allows absolute positioning of child elements */
    height: 100%; /* Make sure it covers the area of the hero section */
  }
  
  .peeking-image {
    transition: all 0.5s ease; /* Smooth transition for any changes */
  }
  
  .left-image {
    /* Additional styles for left image if needed */
  }
  
  .right-image {
    /* Additional styles for right image if needed */
  }